import enLocale from 'element-ui/lib/locale/lang/en'
export default {
    "lang": "fawen",
    // 菜单
    "menu": {
        "souye": "fawen",//媒体
        "data": "data",//数据
        "docking": "docking",//对接
    },
    "footer": {
        "footerTopA": "À Propos de Nous",
        "footerTopB": "Avis de Droits d'Auteur",
        "footerTopC": "Introduction aux affaires",
        "footerTopD": "Contactez-Nous",
        "footerLeftA": "Les informations fournies par ce système sont uniquement destinées à des fins de recherche et ne doivent pas être utilisées comme diagnostic clinique, médication ou preuve médicale.",
        "footerLeftB": "Ce système est développé conjointement par Shandong Soso Traditional Chinese Medicine Information Technology Co., Ltd., en collaboration avec l'Académie Chinoise des Sciences Médicales Traditionnelles et l'Université de Médecine Traditionnelle Chinoise de Shandong.",
        "footerLeftC": "N° d'enregistrement Shandong ICP 13012670-2 Copyright ◎ 2017-2024 Tous droits réservés à IMEDBOOKS",
        "footerRightA": "Compte Officiel de IMEDBOOKS",
        "footerRightB": "Compte Vidéo de IMEDBOOKS",
        "footerRightC": "TikTok de IMEDBOOKS",
    },
    introduction: {
        navList: [
            {
                name: "Service d'organisation de données sur les maladies avantageuses de la médecine traditionnelle chinoise",
            },
            {
                name: "Service de recherche de documents de littérature classique de médecine traditionnelle chinoise",
            },
            {
                name: "Service d'héritage des experts célèbres de la médecine traditionnelle chinoise",
            },
            {
                name: "Service de prise en charge de conférences liées à la médecine traditionnelle chinoise",
            },
            {
                name: "Construction de la plateforme d'information sur la littérature classique de la médecine traditionnelle chinoise",
            },
            {
                name: "Organisation personnalisée de la littérature et des données de médecine traditionnelle chinoise",
            },
            {
                name: "Organisation et organisation de concours de connaissances sur la médecine traditionnelle chinoise",
            },
            {
                name: "Plus",
            },
        ],
    },
    // 中医圈
    ChineseMedicineCircle: {
        title: "Communauté IMEDBOOKS",
        gengduo: "Cliquez pour voir plus",
        search: "Poster un nouveau topic",
    },
    // 活动专栏
    Activitycolumn: {
        title: "Rubrique événementielle",
        gengduo: "Plus"
    },
    // 热门榜单
    HotListView: {
        day: "jours",
        top: "épinglé",
        title: "Classement populaire",
        titleD: "Classement des utilisateurs pour l'apprentissage",
        list: [
            {
                "name": "Meilleurs classements pour la lecture de classiques",
            },
            {
                "name": "Meilleurs classements pour la médecine chinoise",
            },
            {
                "name": "Meilleurs classements pour les prescriptions",
            }
        ]
    },
    // 学术指南
    Academic: {
        title: "Academic guide",
        gengduo: "Plus",
        navList: [
            {
                name: "Documents politiques",
            },
            {
                name: "Directives de diagnostic et de traitement",
            },
            {
                name: "Actualités médicales",
            },
            {
                name: "Dynamique exhaustive",
            },
            {
                name: "Prescriptions classiques pour l'étude et l'application",
            },
            {
                name: "Classiques recommandés",
            },
        ],
    },
    // 方药统计
    Prescription: {
        title: "Statistiques des prescriptions",
        titleB: "Prescription Intelligente",
        contentA: "Statistiques des prescriptions par maladie",
        contentB: "Statistiques des classiques de la matière médicale",
        contentC: "Prescription intelligente adaptée aux symptômes, fournissant des conseils sur les prescriptions de la médecine traditionnelle chinoise pour traiter les maladies et les affections.",
    },
    // 首页顶部
    home: {
        tishi: "Paludisme",
        jinru: "entrer",
        guanbi: "Fermer",
        geren: "Centre personnel",
        tuichu: "Déconnexion",
        search: {
            hotsearch: "Termes de recherche populaires",
            Advanced: "Recherche avancée",
            del: "Supprimer",
            prepend: "Critères de recherche",
            text: "Veuillez entrer le contenu que vous souhaitez rechercher",
            all: "Sélectionner tout",
            alldel: "Effacer tout l'historique de recherche",
        },
        top: {
            welcome: "Bienvenue",
            yewu: "Introduction aux affaires",
            jiaocheng: "guide d'utilisation",
            zhixun: "Ligne de consultation",
        },
        navlist: {
            shouye: "Page d'accueil",
            shujuku: "Base de données",
            xueshu: "Guide académique",
            fangyao: "Statistiques des prescriptions",
            zhihui: "Prescription Intelligente",
            zhongyi: "Communauté IMEDBOOKS",
            remen: "Classement populaire",
            huodong: "Rubrique événementielle",
            chongzhi: "Centre de recharge",
            chongzhiA: "Adhésion de recharge",
            chongzhiB: "Points de recharge",
            Academic: [
                "Documents politiques",
                "Directives de diagnostic et de traitement",
                "Actualités médicales",
                "Dynamique exhaustive",
                "Prescriptions classiques pour l'étude et l'application",
                "Classiques recommandés",
            ],
        }
    },
    // 名词解释
    search: {
        title: "Définition du Nom",
        itemBot: "Issu de",
        shouqi: "Réduire",
        zhankai: "Développer",
        synonym: "Synonymes",
        hypernym: "Hyperonymes",
        hyponym: "Hyponymes",
        Related: "Termes Associés",
        search: "Critères de recherche",
        Publication: "Année de publication",
        shujuku: "Base de Données de Recherche Actuelle",
        mydataTop: "",
        mydataBot: "entrées trouvées. Si vous souhaitez accéder à des privilèges de base de données supplémentaires, veuillez cliquer sur",
        or: "ou",
        huiyuan: "Recharge de l'abonnement",
        kefu: "contacter le service client",
    },
    // 普通检索结果
    searchNav: {
        shujuku: "Base de données",
        facetName: "Classification",
        shouqi: "Réduire",
        zhankai: "Développer",
        Loading: "Chargement...",
        no: "Aucune Donnée Disponible",
        year: "Année de publication",
        chaodai: "dynasties",
        Author: "Auteur",
        list: [
            {
                title: "Entrée"
            },
            {
                title: "Bibliographie"
            },
            {
                title: "Connaissance"
            },
            {
                title: "Audiovisuel"
            },
        ]
    },
    // 条目组件
    clauses: {
        Select: "Sélectionner",
        Please: "Veuillez sélectionner les entrées à exporter",
        all: "Tout sélectionner",
        Export: "Exporter",
        research: "Recherche précise",
        year: "Année de publication",
        Ascending: "Ordre croissant",
        Descending: "Ordre décroissant",
        itemBot: "Issu de",
        shouqi: "Réduire",
        zhankai: "Développer",
        no: "Aucune Donnée Disponible",
        gengduo: "Plus",
        Word: "Réseau de mots",
        Knowledge: "Découverte de connaissances",
        Trend: "Graphique des tendances académiques des livres",
        Prime: "Premier",
        quanbu: "Tous (hors modernes)",
    },
    // 登录
    login: {
        xian: "Veuillez d’abord vous connecter",
        Login: "Connexion",
        text: "Veuillez entrer votre numéro de téléphone/e-mail",
        password: "Veuillez entrer votre mot de passe",
        phone: "Veuillez entrer votre numéro de téléphone",
        yanzheng: "Veuillez entrer le code de vérification",
        fasong: "envoyer le code de vérification",
        verificationA: "Connexion avec code de vérification",
        zhanghao: "Connexion mot de passe",
        register: "S’inscription par mobile",
        Other: "Autres méthodes de connexion",
        mima: "Connexion mot de passe",
        awsile: "Mot de passe oublié",
        ketai: "Récupérer par téléphone",
        email: "Récupérer par e-mail",
        number: "Numéro de téléphone",
        duanxin: "code de vérification sms",
        huoqu: "Obtenir le code de vérification par sms",
        tuxing: "Code de vérification",
        image: "veuillez entrer le code de vérification de l'image",
        Please: "Veuillez entrer votre adresse e-mail",
        verification: "Veuillez entrer le code de vérification de l'e-mail",
        code: "Code de vérification",
        next: "Suivant",
    },
    // 注册
    Register: {
        phone: "Inscription mobile",
        email: "Inscription par e-mail",
        Username: "Nom d'utilisateur：",
        Usernametext: "Veuillez saisir votre nom d'utilisateur",
        nametext: "1 à 12 caractères, peut utiliser le chinois, l'anglais, les chiffres ou une combinaison. Ne peut pas être modifié après l'inscription.",
        ketai: "Numéro de téléphone：",
        ketaitext: "Veuillez entrer votre numéro de téléphone",
        emailNumber: "E-mail：",
        emailNumbername: "Veuillez saisir votre e-mail",
        password: "Mot de passe：",
        tishipassword: "Veuillez définir un mot de passe de connexion de 6 à 20 caractères",
        tishipasswordB: "Veuillez définir un mot de passe de connexion de 6 à 20 caractères",
        tishipasswordC: "Veuillez définir un mot de passe de connexion de 6 à 20 caractères",
        tuxing: "Code de vérification：",
        yanzheng: "Code de vérification：",
        queren: "Confirmer le mot de passe：",
        querentext: "Veuillez saisir à nouveau votre mot de passe",
        jigou: "Institution：",
        jigoutext: "Optionnel, veuillez entrer l'institution à laquelle vous appartenez.",
        jieshou: "J'ai lu et accepté le",
        xieyi: "Contrat d'inscription",
        zhuce: "S'inscrire",
        qita: "Autre mode d'inscription：",
        emailErr: "L’adresse e-mail a déjà été enregistrée",
        UsernameErr: "Le nom d’utilisateur a déjà été enregistré",
        ketaiErr: "Le numéro de téléphone portable a déjà été enregistré",
        phoneErr: "Veuillez entrer votre numéro de téléphone",
        Err: "Veuillez saisir votre e-mail",
    },
    // 数据可视化
    Visualization: {
        title: "Visualisation",
        erji: "Retour au niveau précédent",
        home: "Page d'accueil",
        navList: ["Réseau de mots", "Découverte de connaissances", "Graphique des tendances académiques des livres"],
        navs: ["Graphe de relations", "Diagramme arborescent"],
    },
    // 提示
    Tips: {
        title: "rappel",
        vip: "Vous n’êtes pas encore membre, veuillez d’abord mettre à niveau",
        go: "Aller à recharge",
        cancelText: "Pensez à nouveau",
        yes: "déterminer",
        shujuku: "Pas encore d’accès à la base de données",
        kong: "Le compte ou mot de passe ne peut pas être vide",
        number: "Le numéro de téléphone avec le code international, comme en France 33."
    },
    // 修改密码
    Changepassword: {
        title: "Changer votre mot de passe",
        new: "Nouveau mot de passe",
        newText: "Veuillez entrer un nouveau mot de passe",
        newTwo: "Confirmer le nouveau mot de passe",
        newTwoText: "Veuillez entrer le mot de passe à nouveau",
        ok: "La confirmation",
    },
    ...enLocale
}