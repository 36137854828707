import throttle from "./throttle"
import debounce from "./debounce"

const directives = {
  throttle,
  debounce,
}

export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key])
    })
  },
}
