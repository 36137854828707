export default {
  // 医案
  ConsiliaURL: {
    dev: "http://10.10.32.215:45/",
    prod: "http://medicalcase.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "itemdetails",
    bookType: "20",
  },
  // 本草
  MedicineURL: {
    dev: "http://10.10.32.215:48/",
    prod: "http://herbal.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "itemdetails",
    // bookType: "13",
  },
  // 专科
  SpeciaListURL: {
    dev: "http://10.10.32.215:38/",
    prod: "http://specialist.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "itemdetails",
    bookType: "11",
  },
  // 域外
  AreaURL: {
    dev: "http://10.10.32.215:44/",
    prod: "http://extraterrestrial.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "itemdetails",
    bookType: "14",
  },
  // 基础
  BasicsURL: {
    dev: "http://10.10.32.215:41/",
    prod: "http://basic.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "itemdetails",
    bookType: "1",
  },
  // 珍稀
  RareURL: {
    dev: "http://10.10.32.215:39/",
    prod: "http://rare.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "itemdetails",
    bookType: "15",
  },
  // 病种
  DiseaseURL: {
    dev: "http://10.10.32.215:46/",
    prod: "http://disease.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "home-page/itemcontent",
    bookType: "12",
  },
  // 方剂
  PrescriptionURL: {
    dev: "http://10.10.32.215:47/",
    prod: "http://prescription.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "search/details",
    knowdgeType: "4",
    bookType: "19",
  },
  // 中成药
  patentDetailsURL: {
    dev: "http://10.10.32.215:47/",
    prod: "http://prescription.imedbooks.com/",
    pathName: "patentDetails",
    sectionPath: "search/patentDetails",
    knowdgeType: "9",
    bookType: "19",
  },
  // 经典名方
  ClassicURL: {
    dev: "http://10.10.32.215:47/",
    prod: "http://prescription.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "search/detailsB",
    knowdgeType: "2",
    bookType: "19",
  },
  // 历代名医
  doctorBURL: {
    dev: "http://10.10.32.215:49/",
    prod: "http://doctor.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "SecondaryPage/DynastiesDoctorDetail",
    knowdgeType: "7",
    isKonwdge: true,
    bookType: "4",
  },
  // 国医大师文章
  doctorURL: {
    dev: "http://10.10.32.215:49/",
    prod: "http://doctor.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "SecondaryPage/ArticleDetail",
    knowdgeType: "6",
    isKonwdge: true,
    bookType: "4",
  },
  // 国医大师
  TeacherURL: {
    dev: "http://10.10.32.215:49/",
    prod: "http://doctor.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "SecondaryPage/MedicineDetail",
    isKonwdge: true,
    knowdgeType: "5",
    bookType: "4",
  },
  // 现代医学
  ModerURL: {
    dev: "http://10.10.32.215:51/",
    prod: "http://modern.imedbooks.com/",
    pathName: "home-page/details",
    sectionPath: "home-page/itemcontent",
    bookType: "18",
  },
  // 中医精品网课
  VideoURLB: {
    dev: "http://10.10.32.215:50/",
    prod: "http://onlinecourses.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "SecondaryHome/VideoDetail",
    bookType: "21",
  },
  // 现代医学知识库
  ModerKnowdgeURL: {
    dev: "http://10.10.32.215:51/",
    prod: "http://modern.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "search/detailsB",
    bookType: "18",
    knowdgeType: "1",
  },
  // 中医本草知识库
  MedicineKnowdgeURL: {
    dev: "http://10.10.32.215:48/",
    prod: "http://herbal.imedbooks.com/",
    pathName: "bookdetails",
    sectionPath: "home-page/details",
    bookType: "13",
    knowdgeType: "3",
  },
  // 古籍影印库
  VideoURL: {
    dev: "http://10.10.32.215:60/",
    prod: "http://original.imedbooks.com/",
    pathName: "home-page/details",
    sectionPath: "book-pdf",
    bookType: "17",
  },
  // 针灸推拿
  MedicineURLB: {
    dev: "http://10.10.32.215:54/",
    prod: "http://acupuncture.imedbooks.com/",
    pathName: "home-page/bookdetails",
    sectionPath: "home-page/itemdetails",
    bookType: "23",
  },
  // 针灸推拿知识库
  MedicineKnowdgeURLB: {
    dev: "http://10.10.32.215:54/",
    prod: "http://acupuncture.imedbooks.com/",
    pathName: "home-page/bookdetails",
    sectionPath: "home-page/details",
    bookType: "23",
    knowdgeType: "8",
  },
  // 当代
  contemporaryURL: {
    dev: "http://10.10.32.215:61/",
    prod: "http://contemporary.imedbooks.com/",
    pathName: "home-page/details",
    sectionPath: "home-page/itemcontent",
    bookType: "3",
  },
  // 民国
  republicURL: {
    dev: "http://10.10.32.215:66/",
    prod: "http://republic.imedbooks.com/",
    pathName: "home-page/bookdetails", //书籍路径
    sectionName: "home-page/journaldetails", //期刊路径
    bookType: "16",
  },
};
