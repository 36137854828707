export default {
    // 切换登陆框的显示隐藏
    changeLoginDialog(state, data) {
        state.loginDialog = data
    },
    // 切换登录状态
    changeisToKen(state, data) {
        state.isToKen = data
    }
}
