export default {
  bind: function (el, binding) {
    try {
      let fn,
        event = "click",
        time = 1000
      if (typeof binding.value == "function") {
        fn = binding.value
      } else {
        ;[fn, event = "click", time = 1000] = binding.value
      }
      let timer
      el.addEventListener(event, () => {
        timer && clearTimeout(timer)
        timer = setTimeout(() => fn(), time)
      })
    } catch (e) {
      console.log(e)
    }
  },
}
