import zhLocale from "element-ui/lib/locale/lang/en";
export default {
  lang: "中文",
  // 底部页脚
  footer: {
    footerTopA: "关于我们",
    footerTopB: "版权声明",
    footerTopC: "业务介绍",
    footerTopD: "联系我们",
    footerLeftA: "本系统信息仅供科研参考，不可作为临床诊断、用药及医疗证据",
    footerLeftB:
      "本系统由山东搜搜中医信息科技有限公司与中国中医科学院、山东中医药大学联合研发",
    footerLeftC:
      "鲁ICP备13012670号-2 Copyright © 2017-2024 博览医书 All RightsReserved　版权所有",
    footerRightA: "博览医书公众号",
    footerRightB: "博览医书视频号",
    footerRightC: "博览医书抖音",
  },
  // 首页业务介绍
  introduction: {
    navList: [
      {
        name: "中医优势病种数据整理服务",
      },
      {
        name: "中医古籍文献数据检索",
      },
      {
        name: "名老中医药专家传承服务",
      },
      {
        name: "中医药相关会议承接服务",
      },
      {
        name: "中医药古籍信息化平台建设",
      },
      {
        name: "中医药文献资料整理私人定制",
      },
      {
        name: "中医知识竞赛组织举办",
      },
      {
        name: "更多",
      },
    ],
  },
  // 中医圈
  ChineseMedicineCircle: {
    title: "中医圈",
    gengduo: "点击查看更多",
    search: "发帖子",
  },
  // 活动专栏
  Activitycolumn: {
    title: "活动专栏",
    gengduo: "查看更多",
  },
  // 热门榜单
  HotListView: {
    day: "天",
    top: "置顶",
    title: "热门榜单",
    titleD: "用户学习榜",
    list: [
      {
        name: "阅读古籍top榜",
      },
      {
        name: "中药top榜",
      },
      {
        name: "方剂top榜",
      },
    ],
  },
  // 学术指南
  Academic: {
    title: "学术指南",
    gengduo: "查看更多",
    navList: [
      {
        name: "政策文件",
      },
      {
        name: "诊疗指南",
      },
      {
        name: "医学热点",
      },
      {
        name: "博览动态",
      },
      {
        name: "学用名方",
      },
      {
        name: "古籍推荐",
      },
    ],
  },
  // 方药统计
  Prescription: {
    title: "方药统计",
    titleB: "智慧开方",
    contentA: "病种方药统计",
    contentB: "本草归经统计",
    contentC: "智能化对症开方,为治病医疾提供中医方药借鉴指导",
  },
  // 首页顶部
  home: {
    tishi: "疟疾",
    jinru: "进入",
    guanbi: "关闭",
    geren: "个人中心",
    tuichu: "退出登录",
    search: {
      hotsearch: "热搜词",
      Advanced: "高级检索",
      del: "删除",
      prepend: "检索条件",
      text: "请输入您要检索的内容",
      all: "全选",
      alldel: "清除所有检索历史",
    },
    top: {
      welcome: "欢迎您",
      yewu: "业务介绍",
      jiaocheng: "使用教程",
      zhixun: "咨询热线",
    },
    navlist: {
      shouye: "首页",
      shujuku: "数据库",
      xueshu: "学术指南",
      fangyao: "方药统计",
      zhihui: "智慧开方",
      zhongyi: "中医圈",
      remen: "热门榜单",
      huodong: "活动专栏",
      chongzhi: "充值中心",
      chongzhiA: "充值会员",
      chongzhiB: "积分换购",
      Academic: [
        "政策文件",
        "诊疗指南",
        "医学热点",
        "博览动态",
        "学用名方",
        "古籍推荐",
      ],
    },
  },
  // 名词解释
  search: {
    title: "名词解释",
    itemBot: "出自",
    shouqi: "收起",
    zhankai: "展开",
    synonym: "同义词",
    hypernym: "上位词",
    hyponym: "下位词",
    Related: "相关词",
    search: "检索条件",
    Publication: "成书年代",
    shujuku: "当前检索数据库",
    mydataTop: "博览医书全库共检索到",
    mydataBot: "项条目结果， 如想获取其他数据库权限，请点击",
    or: "或",
    huiyuan: "会员充值",
    kefu: "联系客服",
  },
  // 普通检索结果
  searchNav: {
    shujuku: "数据库",
    facetName: "分类",
    shouqi: "收起",
    zhankai: "展开",
    no: "暂无数据",
    Loading: "加载中...",
    year: "成书年代",
    chaodai: "朝代",
    Author: "作者",
    list: [
      {
        title: "条目",
      },
      {
        title: "书目",
      },
      {
        title: "知识",
      },
      {
        title: "音视频",
      },
    ],
  },
  // 条目组件
  clauses: {
    Select: "选择",
    Please: "请选择导出条目",
    all: "全选",
    Export: "导出",
    research: "精准检索",
    year: "成书年代",
    Ascending: "正序",
    Descending: "倒序",
    itemBot: "出自",
    shouqi: "收起",
    zhankai: "展开",
    no: "暂无数据,请尝试检索其他数据库",
    gengduo: "更多",
    Word: "词网",
    Knowledge: "知识发现",
    Trend: "图书学术趋势图",
    Prime: "底本",
    quanbu: "全部(不含现代)",
  },
  // 登录
  login: {
    xian: "请先登录",
    Login: "登录",
    text: "请输入手机号/邮箱",
    password: "请输入密码",
    phone: "请输入手机号",
    yanzheng: "请输入验证码",
    fasong: "发送验证码",
    verificationA: "验证码登录",
    zhanghao: "账号密码登录",
    register: "去注册",
    Other: "其他登陆方式",
    mima: "密码登录",
    awsile: "忘记密码",
    ketai: "手机取回",
    email: "邮箱取回",
    number: "手机号",
    duanxin: "请输入短信验证码",
    huoqu: "获取短信验证码",
    tuxing: "图片验证码",
    image: "请输入图形验证码",
    Please: "请输入邮箱地址",
    verification: "请输入邮箱验证码",
    code: "验证码",
    next: "下一步",
  },
  // 注册
  Register: {
    phone: "手机注册",
    email: "邮箱注册",
    Username: "用户名：",
    emailErr: "该邮箱已经被注册",
    UsernameErr: "该用户名已经被注册",
    ketaiErr: "该手机号已经被注册",
    Usernametext: "请输入用户名",
    nametext: "1~12个字符,可使用中英文,数字或组合,注册后不可修改",
    ketai: "手机号：",
    ketaitext: "请输入手机号",
    emailNumber: "邮箱：",
    emailNumbername: "请输入邮箱",
    password: "密码：",
    tishipassword: "请设置6-20位登陆密码",
    tishipasswordB: "密码的长度在6-20个字符",
    tishipasswordC: "确认密码的长度在6-20个字符",
    tuxing: "图片验证码：",
    yanzheng: "验证码：",
    queren: "确认密码：",
    querentext: "请再次输入密码",
    jigou: "机构：",
    jigoutext: "选填,请输入您所在的机构",
    jieshou: "我已阅读并接受",
    xieyi: "注册协议",
    zhuce: "注册",
    qita: "其他注册方式：",
    phoneErr: "电话格式:13、14、15、16、17、18、19开头+9位阿拉伯数字",
    Err: "邮箱格式:xx@xx.xx，只含数字、大小写字母、下划线、横杠",
  },
  // 数据可视化
  Visualization: {
    title: "可视化",
    erji: "返回上一级",
    home: "首页",
    navList: ["词网", "知识发现", "图书学术发展趋势图"],
    navs: ["关系图", "树形图"],
  },
  // 提示
  Tips: {
    title: "提示",
    vip: "您还不是会员，请先升级为会员",
    go: "去充值",
    cancelText: "再想想",
    yes: "确定",
    shujuku: "暂无数据库权限",
    kong: "账户或密码不能为空",
    number: "手机号请加地区码，如法国33",
  },
  // 修改密码
  Changepassword: {
    title: "修改密码",
    new: "新密码",
    newText: "请输入新密码",
    newTwo: "确认新密码",
    newTwoText: "请再次输入密码",
    ok: "确认",
  },
  ...zhLocale,
};
