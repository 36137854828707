export default {
  bind: function (el, binding) {
    console.log(el, binding.value)
    let fn,
      event = "click",
      time = 1500
    if (typeof binding.value == "function") {
      fn = binding.value
    } else {
      ;[fn, event = "click", time = 1500] = binding.value
    }

    /**
     * el.preTime 记录上次触发事件，
     * 每次触发比较nowTime（当前时间） 和 el.preTime 的差是否大于指定的时间段！
     */
    el.addEventListener(event, () => {
      const nowTime = new Date().getTime()
      if (!el.preTime || nowTime - el.preTime > time) {
        el.preTime = nowTime
        fn()
      }
    })
  },
}
