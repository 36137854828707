import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/reset.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { Message } from "element-ui";
import Directives from "@/directives/index.js";
import BaiduMap from "vue-baidu-map";
import axios from "@/api/request";

// 引入双语插件
import i18n from "@/i18n/index";

import "./assets/styles/global.css";
// 引入字体
import "./assets/fonts/fonts.css";

// 图片查看工具
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
VueViewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    // "scalable": true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source",
  },
});
Vue.use(VueViewer);
Vue.use(ElementUI);
Vue.use(Directives);

//引入echarts
import * as echarts from "echarts";
//引入组件
Vue.prototype.$echarts = echarts;
/***
 * 防抖 单位时间只触发最后一次
 *  @param {?Number|300} time - 间隔时间
 *  @param {Function} fn - 执行事件
 *  @param {?String|"click"} event - 事件类型 例："click"
 *  @param {Array} binding.value - [fn,event,time]
 *  例：<el-button v-debounce="[reset,`click`,300]">刷新</el-button>
 *  也可简写成：<el-button v-debounce="[reset]">刷新</el-button>
 */
Vue.directive("debounce", {
  inserted: function (el, binding) {
    let timer; // 定时器
    const { fn, params, event, delay } = binding.value;
    el.addEventListener(event || "click", () => {
      // 每次事件被触发时，都去清除之前旧的定时器
      if (timer) clearTimeout(timer);
      // 设立新的定时器
      if (binding.value.hasOwnProperty("params")) {
        // 传参用法：v-debounce="{ fn: 方法名, params: '传参', delay: 防抖时间 }"
        timer = setTimeout(() => {
          fn(params);
        }, delay || 1000);
      } else {
        // 不传参用法：v-debounce="方法名"
        timer = setTimeout(() => {
          binding.value();
        }, 300);
      }
    });
  },
});

Vue.prototype.$message = Message;
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.prototype.$BASE_URL = axios.defaults.baseURL;
console.log(axios.defaults);
// 禁用右键
document.oncontextmenu = new Function("event.returnValue=false");
document.onselectstart = new Function("event.returnValue=false");

Vue.prototype.$domainCookie = window.location.hostname.includes("www")
  ? window.location.hostname.split("www")[1]
  : window.location.hostname;

Vue.config.productionTip = false;

const vueObj = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
Vue.use(BaiduMap, { ak: "xDOCk0c4elP38vVllGf1G68gGOdsSsgN" });
export default vueObj;
